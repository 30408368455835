import { StandardTextFieldProps } from '@material-ui/core';
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab';
import { useMemo } from 'react';
import styled from 'styled-components';
import { TextField } from './TextField';

const Wrapper = styled.div`
  .MuiFormControl-root {
    width: 100%;
  }
  .MuiInputBase-root {
    background-color: white;
  }
`;

export interface AutocompleteProps {
  onChange: (value: string) => void;
  options: Array<{ text: string; value: string }>;
  value: string;
  textFieldProps?: StandardTextFieldProps;
}

export function Autocomplete({
  value,
  options,
  textFieldProps,
  onChange,
}: AutocompleteProps) {
  const valueToSet = useMemo(() => {
    return options.find((v) => v.value === value);
  }, [options, value]);

  return (
    <Wrapper>
      <MuiAutocomplete
        popupIcon={null}
        autoSelect={true}
        options={options}
        value={valueToSet}
        getOptionLabel={(item) => item.text}
        onChange={(_, option) => {
          if (option) {
            onChange(option?.value);
          }
        }}
        renderInput={(params) => <TextField {...params} {...textFieldProps} />}
      />
    </Wrapper>
  );
}
