import { FormControlLabel, Radio } from '@material-ui/core';
import { Inline, Stack, useResponsiveValue } from '@superdispatch/ui';
import * as React from 'react';
import styled from 'styled-components';

const Label = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
`;
const ErrorMessage = styled.div`
  color: #ec310b;
  margin-top: 0.35em;
  display: block;
`;
const StyledFormControlLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    font-size: 16px;
  }
`;

interface RadioGroupProps {
  label: string;
  checked?: number;
  values: Array<{ label: string; value: any }>;
  name: string;
  column?: boolean;
  columnOnMobile?: boolean;
  errorMessage: React.ReactNode;
  onClick: (value: any) => void;
}

export function RadioGroupField({
  values,
  name,
  checked,
  onClick,
  column,
  columnOnMobile,
  label,
  errorMessage,
}: RadioGroupProps) {
  const isMobile = useResponsiveValue(true, true, false);
  const isStack = column || (columnOnMobile && isMobile);

  const renderRadios = values.map((item, key) => (
    <StyledFormControlLabel
      key={key}
      control={<Radio />}
      name={name}
      checked={checked === key}
      label={item.label}
      onChange={() => {
        onClick(item.value);
      }}
    />
  ));

  return (
    <Stack>
      <Label>{label}</Label>
      {isStack ? (
        <Stack space="none">{renderRadios}</Stack>
      ) : (
        <Inline>{renderRadios}</Inline>
      )}
      <ErrorMessage>{errorMessage}</ErrorMessage>
    </Stack>
  );
}
