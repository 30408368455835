import { FieldMetaState, useField } from 'react-final-form';
import styled from 'styled-components';
import { TextField } from './TextField';

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

interface FinalFormTextFieldProps {
  name: string;
  validate: (value: string) => string | undefined;
  label: string;
  maxLength?: number;
  onChange: (value: React.ChangeEvent<HTMLElement>) => void;
  errorMessage: string | undefined;
  helperText?: React.ReactNode;
}

export const errors = (meta: FieldMetaState<unknown>) => {
  if (meta.error && meta.touched) {
    return <span>{meta.error}</span>;
  }
  if (meta.submitError && !meta.touched) {
    return <span>{meta.submitError}</span>;
  }
};

export function FinalFormTextField({
  name,
  validate,
  label,
  maxLength,
  onChange,
  errorMessage,
  helperText,
}: FinalFormTextFieldProps) {
  const { input, meta } = useField(name, { validate });
  const error = errors(meta) || errorMessage;

  return (
    <FieldWrapper>
      <TextField
        {...input}
        label={label}
        inputProps={{
          maxLength,
        }}
        onChange={(e) => {
          input.onChange(e);
          onChange(e);
        }}
        error={!!error}
        helperText={error}
      />
      {helperText}
    </FieldWrapper>
  );
}
