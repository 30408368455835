import { Button as SdButton, ButtonProps } from '@superdispatch/ui';
import styled from 'styled-components';

const StyledButton = styled(SdButton)`
  height: 56px;
`;

export function Button({ ...props }: ButtonProps) {
  return <StyledButton size="large" {...props} />;
}
