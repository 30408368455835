import {
  Checkbox as MuiCheckbox,
  FormControlLabel,
  FormControlLabelProps,
} from '@material-ui/core';
import styled from 'styled-components';

const StyledFormControlLabel = styled(FormControlLabel)`
  align-items: flex-start;
`;

export function Checkbox(props: Omit<FormControlLabelProps, 'control'>) {
  return <StyledFormControlLabel {...props} control={<MuiCheckbox />} />;
}
