import { toString } from 'lodash';

export function trim(value: unknown): string {
  return value == null ? '' : toString(value).trim();
}

export type Printable = null | string | number | boolean | undefined;

export function formatAddressLine(
  zip: null | string | undefined,
  city: null | string | undefined,
  state: null | string | undefined,
  country?: null | string,
) {
  return joinStrings(', ', city, joinStrings(' ', state, zip), country);
}

export function compactStringArray(values: Printable[]): string[] {
  return values.reduce<string[]>((acc, value) => {
    if (value != null && value !== false) {
      const text = trim(value);

      if (text) {
        acc.push(text);
      }
    }

    return acc;
  }, []);
}

export function joinStrings(separator: string, ...args: Printable[]): string {
  return compactStringArray(args).join(separator);
}
