import * as React from 'react';
import { useField, UseFieldConfig } from 'react-final-form';
import { Autocomplete, AutocompleteProps } from '../ui/Autocomplete';

export interface AutoCompleteFieldProps
  extends UseFieldConfig<string, string>,
    Omit<AutocompleteProps, 'value' | 'type' | 'defaultValue' | 'onChange'> {
  name: string;
  label: string;
}

export function AutoCompleteField({
  name,
  label,
  options,
  textFieldProps,
  ...config
}: AutoCompleteFieldProps) {
  const { input, meta } = useField(name, config);
  return (
    <Autocomplete
      options={options}
      value={input.value}
      onChange={input.onChange}
      textFieldProps={{
        error: meta.error && meta.touched,
        helperText: meta.error,
        label,
        onBlur: input.onBlur,
        onFocus: input.onFocus,
        ...textFieldProps,
      }}
    />
  );
}
