import { Box } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import { Inline, Stack } from '@superdispatch/ui';
import { CarrierLogo } from 'carrier/core/CarrierLogo';
import { useEffect, useState } from 'react';
import {
  FieldMetaState,
  useField,
  useForm,
  useFormState,
} from 'react-final-form';
import { formatAddressLine } from 'shared/utils/AddressLine';
import styled from 'styled-components';
import {
  APIErrorResponse,
  isAPIErrorResponse,
  isCarrierDeactivatedErrorResponse,
  verifyUSDOT,
} from '../../data/CarrierAPI';
import { SignupCarrierType } from '../../data/DTOs';
import ArrowForwardIcon from '../../shared/assets/arrow-forward.svg?react';
import CarrierNotFoundImage from '../../shared/assets/carrier-not-found.svg?react';
import RetryIcon from '../../shared/assets/retry.svg?react';
import { trackEvent } from '../../shared/helpers/AnalyticsHelpers';
import { CaptchaWidget } from '../../shared/helpers/CaptchaWidget';
import { openSupportChat } from '../../shared/helpers/intercomHelpers';
import { required } from '../../shared/helpers/validationHelpers';
import { Button } from '../../shared/ui/Button';
import { TextField } from '../../shared/ui/TextField';
import { useCarrierFeatureToggles } from '../../shared/useCarrierFeatureToggles';
import { logInfo, logWarning } from '../../shared/utils/datadog';
import { sendToWebhook } from '../../shared/webhooks/webhook';
import { NoUSDOTDialog } from '../core/NoUSDOTDialog';

const Wrapper = styled.div`
  margin-top: 32px;
`;
const StyledButton = styled(Button)<{ hasMarginBottom?: boolean }>`
  margin-top: 16px;
`;

const RetryButton = styled(Button)`
  && {
    padding: 0;
    max-width: 200px;
    span {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
    }
  }
`;

const StyledTextField = styled(TextField)`
  .MuiFormControl-root {
    width: 100%;
  }
`;
const StyledArrowForwardIcon = styled(ArrowForwardIcon)`
  margin-left: 10px;
`;
const UserMessage = styled.div<{ background?: string }>`
  background: ${({ background }) => background || '#f3f5f8'};
  border-radius: 4px;
  padding: 16px;
  font-size: 16px;
  line-height: 24px;
  color: #192334;
  margin-top: 32px;
`;
const MessageText = styled.span<{
  secondary?: boolean;
  header?: boolean;
  center?: boolean;
}>`
  line-height: 24px;
  font-size: ${({ header }) => (header ? '20px' : '16px')};
  color: ${({ secondary }) => (secondary ? '#6a707c' : '#192334')};
  font-weight: ${({ header }) => (header ? '600' : '400')};
  text-align: ${({ center }) => (center ? 'center' : 'left')};
`;
const MessageTextSecondary = styled(MessageText)`
  color: #6a707c;
`;
const MessageTextBold = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #192334;
  font-weight: 600;
  margin-top: 16px;
`;
const StyledSDKitButton = styled(Button)`
  min-height: 40px;
  margin-top: 16px;
  && {
    border: 1px solid #c4cdd5;
  }
`;

const Alert = styled.div`
  margin-bottom: 32px;
  background-color: #f3f5f8;
  padding: 16px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const AlertText = styled.p<{ primary?: boolean; bold?: boolean }>`
  margin: 0;
  font-size: 16px;
  font-weight: ${({ bold }) => (bold ? '600' : '400')};
  line-height: 24px;
  letter-spacing: 0.35px;
  color: ${({ primary }) => (primary ? '#192334' : '#6a707c')};
`;

const List = styled.ul`
  margin-block-start: 8px;
  margin-block-end: 0;
  padding-inline-start: 24px;
  li {
    margin-bottom: 8px;
    font-size: 14;
    font-weight: 400;
    a {
      color: #0070f5;
      text-decoration: none;
    }
  }
`;

const TextButton = styled(Button)`
  && {
    margin-top: 16px;
    color: #0070f5;
    border: none;
    &:hover {
      background-color: #ebf4ff;
    }
  }
`;

const FormWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 16px;
  width: 100%;
  .MuiFormControl-root {
    width: 100%;
    min-width: 150px;
  }
  .MuiButtonBase-root {
    padding: 0px;
    min-width: 120px;
  }
  .MuiButton-outlined {
    min-width: 150px;
  }
  @media (max-width: 768px) {
    .MuiButtonBase-root {
      height: 64px;
    }
  }
`;

const CarrierWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`;

const CarrierContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const CarrierTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const NoCarrierData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-top: 32px;
`;

const errors = (meta: FieldMetaState<unknown>) => {
  if (meta.error && meta.touched) {
    return <span>{meta.error}</span>;
  }
  if (meta.submitError && meta.touched) {
    return <span>{meta.submitError}</span>;
  }
};

interface VerifyUSDOTProps {
  usdot: string;
  submitting?: boolean;
  captchaToken: string | null;
  onCaptchaTokenChange: (token: string | null) => void;
  verifiedUSDOT: string;
  setVerifiedUSDOT: (usdot: string) => void;
  wizardData: SignupCarrierType & { carrierData?: any; carrierStatus: boolean };
  onCarrierStatusChange: (status: boolean, carrierData: any) => void;
}

export function VerifyUSDOT({
  usdot,
  wizardData,
  submitting,
  captchaToken,
  onCaptchaTokenChange,
  onCarrierStatusChange,
  verifiedUSDOT,
  setVerifiedUSDOT,
}: VerifyUSDOTProps) {
  const { submitError } = useFormState();
  const { change } = useForm();

  const [isRegistered, setIsRegistered] = useState(wizardData.carrierStatus);
  const [error, setError] = useState<APIErrorResponse['error']>();
  const [isVerifying, setVerifying] = useState(false);
  const [openNoUsdotModal, setOpenNoUsdotModal] = useState(false);
  const [carrierData, setCarrierData] = useState(wizardData.carrierData);
  const { features } = useCarrierFeatureToggles();
  const { input, meta } = useField('usdot', {
    validate: required('Please enter USDOT'),
  });

  useEffect(() => {
    document.title = 'Carrier Sign Up | Verify USDOT';

    return () => {
      input.onChange('');
    };
  }, []);

  useEffect(() => {
    if (import.meta.env.VITE_TARGET === 'production') {
      void sendToWebhook({
        first_name: wizardData.first_name,
        last_name: wizardData.last_name,
        email: wizardData.email,
        phone_number: wizardData.phoneNumber,
        email_subscription: wizardData.subscriptions,
        sms_subscription: wizardData.subscriptions,
        role: wizardData.role,
        number_of_trucks: wizardData.number_of_trucks,
        source: 'CTMS_Website',
      });
    }
  }, [wizardData]);

  const handleUSDOTVerification = async () => {
    if (usdot && !isVerifying) {
      setVerifying(true);

      trackEvent('Verify USDOT clicked');

      const response = await verifyUSDOT(usdot);

      if (isAPIErrorResponse(response)) {
        setError(response.error);
        logWarning('Carrier USDOT verification failed', {
          usdot,
          response,
        });
      }

      if (response.data) {
        logInfo('Carrier USDOT verified', { usdot });
        trackEvent('USDOT verified');

        const isJoin = response.data.carrier_status === 'registered';
        setIsRegistered(isJoin);
        onCarrierStatusChange(isJoin, response.data.carrier);

        if (isJoin) {
          setCarrierData(response.data.carrier);
        } else {
          setCarrierData(response.data);
          onCarrierStatusChange(isJoin, response.data);
        }
        setError(undefined);
      } else {
        setCarrierData(undefined);
        setIsRegistered(false);
        onCarrierStatusChange(false, undefined);
      }
      setVerifiedUSDOT(usdot);
      setVerifying(false);
    }
  };

  const handleContactSupportClick = () => {
    openSupportChat();
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.which === 13) {
      e.preventDefault();
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      !isVerifying && handleUSDOTVerification();
    }
  };

  return (
    <Wrapper>
      {features?.no_usdot_signup && (
        <Alert>
          <AlertText>Note:</AlertText>
          <AlertText primary={true}>
            Your USDOT number is issued by FMCSA.
          </AlertText>
          <AlertText primary={true} bold={true}>
            What if I don’t know or have USDOT?
          </AlertText>
          <List>
            <li>
              If you're joining an existing Super Dispatch carrier, ask your
              account admin to invite you.
            </li>
            <li>
              Find your USDOT number on the{' '}
              <a
                href="https://safer.fmcsa.dot.gov/CompanySnapshot.aspx"
                target="_blank"
              >
                SAFER website
              </a>
            </li>
            <li>
              <a
                href="https://portal.fmcsa.dot.gov/UrsRegistrationWizard/"
                target="_blank"
              >
                Apply for a USDOT number
              </a>
            </li>
          </List>
        </Alert>
      )}
      <FormWrapper>
        <StyledTextField
          {...input}
          autoFocus={true}
          fullWidth={true}
          label="USDOT"
          onBlur={(e) => {
            e.preventDefault();
          }}
          onWheel={(e) => {
            // to prevent the user from scrolling the input and change the value
            e.currentTarget.blur();
          }}
          onKeyPress={handleKeyPress}
          type="number"
          disabled={isVerifying}
          inputProps={{
            maxLength: 255,
            min: 0,
          }}
          error={!!errors(meta)}
          helperText={errors(meta)}
          placeholder="Your company USDOT"
        />

        {!carrierData && !error && features?.no_usdot_signup && (
          <>
            <StyledButton
              isLoading={isVerifying}
              type={usdot === '' ? 'submit' : 'button'}
              onClick={handleUSDOTVerification}
              disabled={input.value === ''}
              variant="contained"
            >
              <Inline verticalAlign="center">
                Verify <ArrowForward />
              </Inline>
            </StyledButton>
            {errors(meta) && <Box height="24px" />}
          </>
        )}
        {features?.no_usdot_signup && (carrierData || error) && (
          <RetryButton
            isLoading={isVerifying}
            disabled={usdot === ''}
            type={usdot === '' ? 'submit' : 'button'}
            onClick={handleUSDOTVerification}
            startIcon={<RetryIcon />}
          >
            Verification
          </RetryButton>
        )}
      </FormWrapper>

      {!carrierData && !error && features?.no_usdot_signup && (
        <TextButton
          type="button"
          variant="text"
          fullWidth={true}
          onClick={() => {
            setOpenNoUsdotModal(true);
          }}
        >
          I don&apos;t have USDOT
        </TextButton>
      )}

      {(carrierData || error) && !features?.no_usdot_signup && (
        <StyledSDKitButton
          isLoading={isVerifying}
          disabled={usdot === ''}
          type={usdot === '' ? 'submit' : 'button'}
          onClick={handleUSDOTVerification}
        >
          Retry Verification
        </StyledSDKitButton>
      )}

      {error &&
        !features?.no_usdot_signup &&
        (isCarrierDeactivatedErrorResponse(error) ? (
          <UserMessage background="#FFF9E5">
            <MessageTextSecondary>{error.user_message}</MessageTextSecondary>
            <br />
            <MessageTextBold>{error.context.carrier.name}</MessageTextBold>
            <MessageTextSecondary>
              {error.context.carrier.city}, {error.context.carrier.state}{' '}
              {error.context.carrier.zip} {error.context.carrier.phone_numbers}
            </MessageTextSecondary>
          </UserMessage>
        ) : (
          <UserMessage>{error.user_message}</UserMessage>
        ))}

      {carrierData && !features?.no_usdot_signup && (
        <>
          {!isRegistered && (
            <UserMessage>
              <MessageText>
                Great! We will create your account using the information below:
              </MessageText>
              <MessageTextBold>{carrierData.carrier_name}</MessageTextBold>
            </UserMessage>
          )}
          {isRegistered && (
            <UserMessage>
              <MessageText>
                Carrier with this USDOT is already registered. Join this
                carrier?
              </MessageText>
              <MessageTextBold>{carrierData.name}</MessageTextBold>
              <MessageText>{`${carrierData.city}, ${carrierData.state} ${carrierData.zip} ${carrierData.phone_numbers}`}</MessageText>
            </UserMessage>
          )}
        </>
      )}

      {carrierData && features?.no_usdot_signup && (
        <CarrierWrapper>
          <UserMessage>
            {isRegistered ? (
              <MessageText secondary={true}>
                Carrier with this USDOT is already registered. Would you like to
                join this carrier?
              </MessageText>
            ) : (
              <MessageText secondary={true}>
                Great! We will create your account using the information below:
              </MessageText>
            )}
          </UserMessage>
          <CarrierContent>
            <CarrierLogo
              img={isRegistered ? carrierData?.img : ''}
              name={isRegistered ? carrierData.name : carrierData.carrier_name}
            />

            <CarrierTextWrapper>
              <MessageText center={true} header={true}>
                {isRegistered ? carrierData.name : carrierData.carrier_name}
              </MessageText>
              <MessageText>
                {isRegistered
                  ? formatAddressLine(
                      carrierData.zip,
                      carrierData.city,
                      carrierData.state,
                      carrierData.country,
                    )
                  : formatAddressLine(
                      carrierData.carrier_zip,
                      carrierData.carrier_city,
                      carrierData.carrier_state,
                      carrierData.carrier_country,
                    )}
              </MessageText>
            </CarrierTextWrapper>

            <MessageText secondary={true}>USDOT: {verifiedUSDOT}</MessageText>
          </CarrierContent>
        </CarrierWrapper>
      )}

      {error && features?.no_usdot_signup && (
        <NoCarrierData>
          <CarrierNotFoundImage />
          <MessageText header={true}>Carrier Not Found</MessageText>
          <MessageText center={true}>
            We couldn’t find a carrier with USDOT <b>{verifiedUSDOT}</b>. Please
            double-check the USDOT number. If the issue persists, contact our
            support team for assistance.
          </MessageText>
        </NoCarrierData>
      )}

      <Wrapper>
        <CaptchaWidget onTokenChange={onCaptchaTokenChange} />
      </Wrapper>

      {!carrierData && !error && !features?.no_usdot_signup && (
        <StyledButton
          isLoading={isVerifying}
          type={usdot === '' ? 'submit' : 'button'}
          onClick={handleUSDOTVerification}
          variant="contained"
        >
          <Inline>
            Verify <StyledArrowForwardIcon />
          </Inline>
        </StyledButton>
      )}

      {typeof submitError === 'string' && (
        <UserMessage background="#FFF9E5">{submitError}</UserMessage>
      )}

      <Stack space="xxsmall">
        {error && (
          <StyledButton
            type="button"
            fullWidth={true}
            onClick={handleContactSupportClick}
          >
            Contact Support
          </StyledButton>
        )}

        {!!error && features?.no_usdot_signup && (
          <TextButton
            type="button"
            fullWidth={true}
            variant="text"
            onClick={() => {
              setOpenNoUsdotModal(true);
            }}
          >
            I don&apos;t have USDOT
          </TextButton>
        )}
      </Stack>

      {carrierData && !features?.no_usdot_signup && (
        <StyledButton
          type="submit"
          variant="contained"
          color="primary"
          disabled={!verifiedUSDOT || submitting}
          isLoading={submitting}
        >
          {isRegistered ? 'Request to Join Carrier' : 'Continue'}{' '}
          <StyledArrowForwardIcon />
        </StyledButton>
      )}

      {carrierData && features?.no_usdot_signup && (
        <StyledButton
          type="submit"
          disabled={!verifiedUSDOT || submitting}
          isLoading={submitting}
          variant="contained"
          color="primary"
          fullWidth={true}
        >
          {isRegistered ? 'Join This Carrier' : 'Complete Sign Up'}{' '}
          <StyledArrowForwardIcon />
        </StyledButton>
      )}

      {isRegistered && (
        <StyledButton type="button" onClick={handleContactSupportClick}>
          Contact Support
        </StyledButton>
      )}

      <NoUSDOTDialog
        open={openNoUsdotModal}
        captchaToken={captchaToken}
        onClose={() => {
          setOpenNoUsdotModal(false);
          change('is_interstate', false);
          change('exempt_states', false);
          change('combined_truck', false);
        }}
      />
    </Wrapper>
  );
}
