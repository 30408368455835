import * as React from 'react';
import { Field, FieldMetaState, useFormState } from 'react-final-form';
import { AutoCompleteField } from 'shared/form/AutocompleteField';
import { Checkbox } from 'shared/form/Checkbox';
import { PhoneField } from 'shared/form/PhoneField';
import styled from 'styled-components';
import ArrowForwardIcon from '../../shared/assets/arrow-forward.svg?react';
import { countryOptions } from '../../shared/form/CountryOptions';
import {
  composeValidators,
  disallowHtml,
  isValidCompanyName,
  isValidName,
  isValidPhone,
} from '../../shared/helpers/validationHelpers';
import { Button } from '../../shared/ui/Button';
import { FormGroup } from '../../shared/ui/FormGroup';
import { TextField } from '../../shared/ui/TextField';
import { useCarrierFeatureToggles } from '../../shared/useCarrierFeatureToggles';

const Wrapper = styled.div`
  margin-top: 20px;
`;
const StyledArrowForwardIcon = styled(ArrowForwardIcon)`
  margin-left: 10px;
`;

const validations = {
  firsName: composeValidators([
    isValidName('Please enter a first name'),
    disallowHtml(),
  ]),
  last_name: composeValidators([
    isValidName('Please enter a last name'),
    disallowHtml(),
  ]),
  phone: isValidPhone('Please enter a valid phone number'),
  company_name: composeValidators([
    isValidCompanyName('Enter a company name'),
    disallowHtml(),
  ]),
};

const errors = (meta: FieldMetaState<unknown>) => {
  if (meta.error && meta.touched) {
    return <span>{meta.error}</span>;
  }
  if (meta.submitError && !meta.touched) {
    return <span>{meta.submitError}</span>;
  }
};

export function ContactInfo() {
  const { submitErrors } = useFormState();
  const { features } = useCarrierFeatureToggles();

  React.useEffect(() => {
    document.title = 'Carrier Sign Up | Contact Info';
  }, []);

  return (
    <Wrapper>
      {features?.no_usdot_signup && (
        <FormGroup>
          <AutoCompleteField
            name="country"
            label="Country"
            options={countryOptions}
          />
        </FormGroup>
      )}
      <FormGroup>
        <Field name="company_name" validate={validations.company_name}>
          {({ input, meta }) => (
            <TextField
              {...input}
              label="Company Name"
              autoFocus={!submitErrors}
              error={!!errors(meta)}
              helperText={errors(meta)}
              inputProps={{
                maxLength: 255,
              }}
            />
          )}
        </Field>
      </FormGroup>
      <FormGroup>
        <Field name="first_name" validate={validations.firsName}>
          {({ input, meta }) => (
            <TextField
              {...input}
              label="First Name"
              error={!!errors(meta)}
              helperText={errors(meta)}
              inputProps={{
                maxLength: 255,
              }}
            />
          )}
        </Field>
        <Field name="last_name" validate={validations.last_name}>
          {({ input, meta }) => (
            <TextField
              {...input}
              label="Last Name"
              error={!!errors(meta)}
              helperText={errors(meta)}
              inputProps={{
                maxLength: 255,
              }}
            />
          )}
        </Field>
      </FormGroup>

      <FormGroup>
        <PhoneField name="phoneNumber" />
      </FormGroup>

      <FormGroup>
        <Field type="checkbox" name="subscriptions">
          {({ input }) => (
            <Checkbox
              {...input}
              checked={input.value}
              label="I agree to receive SMS and Email messages from Super Dispatch such as the latest offers and deals."
            />
          )}
        </Field>
      </FormGroup>

      <Button variant="contained" size="large" type="submit" fullWidth={true}>
        Continue <StyledArrowForwardIcon />
      </Button>
    </Wrapper>
  );
}
