import { SignupShipperData } from '../../data/DTOs';
import {
  identifyAnalytics,
  trackEvent,
} from '../../shared/helpers/AnalyticsHelpers';
import { sendToWebhook } from '../../shared/webhooks/webhook';

export const sendFivetranWebhookEvent = (e: SignupShipperData) => {
  const signupData = {
    company_name: e.companyName,
    company_type: e.companyType,
    email: e.email,
    first_name: e.firstName,
    heard_about_us:
      e.comment !== ''
        ? e.comment === 'Other'
          ? e.referralSource
          : e.comment
        : undefined,
    last_name: e.lastName,
    moves_cars_per_month: e.carsPerMonth,
    phone_number: e.phoneNumber,
    source: 'STMS_Website',
    email_subscription: e.subscriptions,
    sms_subscription: e.subscriptions,
    country: e.country,
    state: e.state,
  };

  return sendToWebhook(signupData);
};

export const identifyUserOnSegment = (
  userId: string,
  companyId: string | undefined,
  e: SignupShipperData,
  enableSalesForce = false,
) => {
  const contact = {
    email: e.email,
    firstName: e.firstName,
    lastName: e.lastName,
    phone: e.phoneNumber,
    heard_about_us:
      e.comment !== ''
        ? e.comment === 'Other'
          ? e.referralSource
          : e.comment
        : undefined,
    company_name: e.companyName,
    company_type: e.companyType,
    cars_per_month: e.carsPerMonth,
    email_subscription: e.subscriptions,
    sms_subscription: e.subscriptions,
    source: 'STMS_Website',
  };

  identifyAnalytics(
    userId,
    {
      ...contact,
      companyType: e.companyType,
      creationSource: 'Website',
      company: { id: companyId, name: e.companyName },
    },
    {
      integrations: {
        Salesforce: enableSalesForce,
      },
    },
  );
};

export function trackSignedUpEvent(companyType: string, isSelfServe = false) {
  trackEvent('Signed up', {
    isSelfServe,
    companyType,
  });
}

export function redirectToShipperTMS(url: string, token: string) {
  const shipperUrl = new URL(url);
  shipperUrl.searchParams.append('token', token);
  shipperUrl.searchParams.append('utm_source', 'shipper_signup');
  shipperUrl.pathname = '/getting-started';
  window.location.assign(shipperUrl.toString());
}

type DeepRecordKeyToString<TTarget, TValue> = {
  [TKey in keyof TTarget]?: TTarget[TKey] extends Record<string, unknown>
    ? DeepRecordKeyToString<TTarget[TKey], TValue>
    : TValue;
};

export type SignupShipperValidationErrors = DeepRecordKeyToString<
  SignupShipperData,
  string | undefined
>;

export function checkHasAccountInformationErrors(
  errors: SignupShipperValidationErrors,
): boolean {
  return Boolean(
    errors.firstName ||
      errors.lastName ||
      errors.companyName ||
      errors.email ||
      errors.phoneNumber ||
      errors.companyType ||
      errors.carsPerMonth ||
      errors.comment ||
      errors.subscriptions ||
      errors.referralSource ||
      errors.country ||
      errors.state ||
      errors.password,
  );
}

export function checkHasPaymentInformationErrors(
  errors: SignupShipperValidationErrors,
): boolean {
  return Boolean(
    errors.paymentInfo?.address ||
      errors.paymentInfo?.city ||
      errors.paymentInfo?.state ||
      errors.paymentInfo?.zip ||
      errors.paymentInfo?.country,
  );
}
