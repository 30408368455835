import { joinCarrier, signupCarrier } from '../../data/CarrierAPI';
import { useMemo, useState } from 'react';
import { WizardDataType } from '../../core/Wizard';
import { captureError, logInfo, logWarning } from 'shared/utils/datadog';
import { isChromium } from 'shared/helpers/browserHelpers';
import { identifyAnalytics } from 'shared/helpers/AnalyticsHelpers';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { openSupportChat } from 'shared/helpers/intercomHelpers';
import { FORM_ERROR } from 'final-form';
import { useLocation } from 'react-router-dom';
import { useInitialState } from 'shared/helpers/useInitialState';
import { parseSearchParams } from 'shared/helpers/URLHelpers';
import { SignupCarrierType } from 'data/DTOs';

export function useSignupCarrier() {
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [carrierData, setCarrierData] = useState<any>();
  const [joinEmail, setJoinEmail] = useState();
  const [verifiedUSDOT, setVerifiedUSDOT] = useState('');

  const [createAccountTitle, setCreateAccountTitle] =
    useState('Create Account');
  const { search } = useLocation();
  const initialSearchState = useInitialState(search);

  const initialValues = useMemo<SignupCarrierType>(() => {
    const searchParams = parseSearchParams(initialSearchState || '');
    return {
      usdot: '',
      first_name: searchParams.first_name || '',
      last_name: searchParams.last_name || '',
      email: searchParams.email || '',
      password: '',
      role: '',
      role_other: '',
      number_of_trucks: 0,
      phoneNumber: '',
      heard_about_us: '',
      companyType: 'carrier',
      subscriptions: false,
      broker_name: '',
      referralSource: '',
      is_interstate: false,
      exempt_states: false,
      combined_truck: false,
      country: 'US',
    };
  }, [initialSearchState]);

  const handleSubmit = async (values: any, wizardData: WizardDataType) => {
    if (!captchaToken) {
      logWarning('Carrier missing Captcha token', values);
      // eslint-disable-next-line no-alert
      alert('Failed to verify Captcha. Please try again.');
      return;
    }

    if (isSubmitting) {
      return;
    }

    try {
      setSubmitting(true);
      if (!isRegistered || values.country !== 'US') {
        const signupData = {
          ...(values.country === 'US' && { usdot: verifiedUSDOT }),
          country: values.country,
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          password: values.password,
          phone_number: values.phoneNumber,
          role:
            values.role === 'Other'
              ? values.role_other.toLowerCase().replace(/\s/g, '_')
              : values.role.toLowerCase().replace(/\s/g, '_'),
          number_of_trucks: values.number_of_trucks,
          interested_in_eld: values.interested_in_eld,
          heard_about_us:
            values.comment !== ''
              ? values.comment === 'Other'
                ? values.referralSource
                : values.comment
              : undefined,
          company_type: 'carrier',
          email_subscription: values.subscriptions,
          sms_subscription: values.subscriptions,
          broker_name: values.broker_name,
          company_name: values.company_name,
          browser: isChromium() ? 'chromium' : 'other',
          cf_turnstile_response: captchaToken,
        };

        const { error, url, meta } = await signupCarrier(signupData);
        setSubmitting(false);

        if (meta.code === 302) {
          identifyAnalytics(values.email, {
            email: values.email,
            name: values.first_name,
            companyName: values.company_name,
          });

          trackEvent('Sign up submitted', {
            companyName: values.company_name,
            companyType: wizardData.companyType,
          });
          logInfo('Carrier Sign up submitted', {
            ...signupData,
            password: null,
            cf_turnstile_response: null,
          });

          window.location.href = `${url}&next=/tms/loads?utm_source=carrier_signup`;
        } else {
          logWarning('Carrier signup failed', {
            error,
            values: {
              ...signupData,
              password: null,
              cf_turnstile_response: null,
            },
          });

          const validationErrors =
            error.type === 'ValidationError' &&
            !!error.context &&
            formatAPIErrors(error.context);

          if (validationErrors) {
            if (validationErrors.email || validationErrors.phone_number) {
              history.go(-2);
            }

            return validationErrors;
          }

          return {
            [FORM_ERROR]:
              error.user_message ||
              error.dev_message ||
              error.type ||
              'Failed to submit',
          };
        }
      } else {
        const signupData = {
          ...(values.country === 'US' && { usdot: verifiedUSDOT }),
          country: values.country,
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          password: values.password,
          number_of_trucks: values.number_of_trucks,
          role:
            values.role === 'Other'
              ? values.role_other.toLowerCase()
              : values.role.toLowerCase().replace(/\s/g, '_'),
          phone_number: values.phoneNumber,
          heard_about_us:
            values.comment !== ''
              ? values.comment === 'Other'
                ? values.referralSource
                : values.comment
              : undefined,
          company_type:
            values.number_of_trucks === '1' ? 'owner_operator' : 'fleet',
          email_subscription: values.subscriptions,
          sms_subscription: values.subscriptions,
          broker_name: values.broker_name,
          browser: isChromium() ? 'chromium' : 'other',
          cf_turnstile_response: captchaToken,
        };

        const { error, user_message, meta } = await joinCarrier(
          signupData,
          carrierData?.guid,
        );
        setSubmitting(false);

        if (meta.code === 200) {
          setJoinEmail(
            user_message
              ? user_message.match(
                  new RegExp(
                    /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi,
                  ),
                )[0]
              : '',
          );

          identifyAnalytics(values.email, {
            email: values.email,
            name: values.first_name,
            company_name: values.company_name,
          });
          trackEvent('Join request sent', {
            companyType:
              values.number_of_trucks === '1' ? 'owner_operator' : 'fleet',
          });
          logInfo('Carrier Join request sent', {
            ...signupData,
            password: null,
            cf_turnstile_response: null,
          });
        } else {
          logWarning('Carrier join failed', {
            error,
            values: {
              ...signupData,
              password: null,
              cf_turnstile_response: null,
            },
          });

          const validationErrors =
            error.type === 'ValidationError' &&
            !!error.context &&
            formatAPIErrors(error.context);

          if (validationErrors) {
            if (validationErrors.email || validationErrors.phone_number) {
              history.go(-2);
            }

            return validationErrors;
          }

          return {
            [FORM_ERROR]:
              error.user_message ||
              error.dev_message ||
              error.type ||
              'Failed to submit',
          };
        }
      }
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.log(e);
      setSubmitting(false);
      // eslint-disable-next-line no-alert
      alert('Something went wrong! Please contact support!');
      openSupportChat();
      captureError(e, 'CarrierSignup#submit', {
        ...values,
        password: null,
      });
    }
  };

  return {
    verifiedUSDOT,
    setVerifiedUSDOT,
    createAccountTitle,
    setCreateAccountTitle,
    handleSubmit,
    isSubmitting,
    initialValues,
    setCarrierData,
    setCaptchaToken,
    joinEmail,
    setIsRegistered,
    captchaToken,
  };
}

function formatAPIErrors(
  context: Record<string, unknown>,
): Record<string, string> | undefined {
  const errors: Record<string, string> = {};

  for (const field in context) {
    const error = context[field];
    if (Array.isArray(error)) {
      errors[field] = error.join(', ');
    } else if (typeof error === 'string') {
      errors[field] = error;
    }
  }

  if (Object.keys(errors).length) {
    return errors;
  }

  return undefined;
}
