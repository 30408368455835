import { MenuItem } from '@material-ui/core';
import { TextField } from 'shared/ui/TextField';
import styled, { css } from 'styled-components';
import Logo from '../assets/logo.svg?react';

const Wrapper = styled.div<{ half?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 8vw;
  ${({ half }) =>
    half &&
    css`
      padding: 16px 2vw;
      padding-left: 30%;
    `}
  background: #ffffff;
  box-shadow: inset 0px -1px 0px #e1e5ea;
  @media (max-width: 1024px) {
    padding: 16px 5%;
  }
`;

const loginUrls: any = {
  carrier: 'https://carrier.superdispatch.com/tms/login/',
  shipper: 'https://shipper.superdispatch.com/signin',
};

interface HeaderProps {
  type: 'main' | 'carrier' | 'shipper';
  half?: boolean;
}

export const Header = ({ type, half }: HeaderProps) => (
  <Wrapper half={half}>
    <a
      href={
        type === 'main'
          ? 'https://superdispatch.com'
          : 'https://superdispatch.com/signup'
      }
    >
      <Logo />
    </a>
    <TextField
      value=""
      select={true}
      onChange={(e) => {
        window.location.href = loginUrls[e.target.value];
      }}
      SelectProps={{
        displayEmpty: true,
        renderValue: (selected) => {
          if (!selected) {
            return 'Login';
          }

          return selected as string;
        },
      }}
    >
      <MenuItem value="carrier">Carrier TMS</MenuItem>
      <MenuItem value="shipper">Shipper TMS</MenuItem>
    </TextField>
  </Wrapper>
);
