import { Dialog } from '@material-ui/core';
import { useState } from 'react';
import { Field, useFormState } from 'react-final-form';
import { Checkbox } from 'shared/form/Checkbox';
import { Button } from 'shared/ui/Button';
import styled from 'styled-components';
import { signupCarrier } from '../../data/CarrierAPI';
import USDOTWarningIcon from '../../shared/assets/usdot-warning.svg?react';
import { trackEvent } from '../../shared/helpers/AnalyticsHelpers';
import { logWarning } from '../../shared/utils/datadog';

const DialogBody = styled.div`
  display: flex;
  gap: 32px;
  flex-direction: column;
  align-items: center;
  padding: 24px;
`;

const DialogFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

const Text = styled.p<{ bold?: boolean }>`
  font-size: 14px;
  font-weight: ${({ bold }) => (bold ? 600 : 400)};
  line-height: 24px;
  color: #192334;
  margin: 0;
`;

const TextHeader = styled.h2<{ center?: boolean }>`
  width: 100%;
  font-size: 24px;
  color: #192334;
  font-weight: 500;
  line-height: 28px;
  text-align: ${({ center }) => (center ? 'center' : 'left')};
`;

const Link = styled.a`
  cursor: pointer;
  color: #0070f5;
  text-decoration: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
`;

const Label = styled.p`
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #192334;
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 0 0 0 3px;
`;

const CheckboxGroup = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 8px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

const WarningAlert = styled.div`
  padding: 16px;
  border-radius: 4px;
  background-color: #fff7dc;
`;

const List = styled.ul`
  margin-block-start: 8px;
  margin-block-end: 0;
  padding-inline-start: 24px;
  li {
    margin-bottom: 8px;
    font-size: 14;
    font-weight: 400;
    a {
      color: #0070f5;
      text-decoration: none;
    }
  }
`;

const usdotReasons = [
  {
    label: 'I am an intrastate carrier operating exclusively within my state',
    name: 'is_interstate',
    link: '',
    linkText: '',
  },
  {
    label: 'I operate within one of the',
    name: 'exempt_states',
    linkText: '12 exempt states',
    link: 'https://www.fmcsa.dot.gov/registration/do-i-need-usdot-number',
  },
  {
    label:
      'My vehicle is a power unit under 10,001 lbs, or the combined weight of my truck, trailer, and cargo is under 26,000 lbs',
    name: 'combined_truck',
    link: '',
    linkText: '',
  },
];

interface NoUSDOTDialogProps {
  onClose: () => void;
  open: boolean;
  captchaToken: string | null;
}

export function NoUSDOTDialog({
  onClose,
  open,
  captchaToken,
}: NoUSDOTDialogProps) {
  const [verifying, setVerifying] = useState(false);
  const { values } = useFormState();

  const isNotDisabled =
    values.is_interstate && values.exempt_states && values.combined_truck;

  const handleContinueWithoutUSDOT = async () => {
    if (isNotDisabled) {
      if (!captchaToken) {
        logWarning('Carrier missing Captcha token', values);
        // eslint-disable-next-line no-alert
        alert('Failed to verify Captcha. Please try again.');
        return;
      }
      setVerifying(true);

      const { usdot, ...carrierData } = values;

      const { url } = await signupCarrier({
        ...carrierData,
        company_type: values.number_of_trucks > 1 ? 'fleet' : 'owner_operator',
        cf_turnstile_response: captchaToken,
      });

      if (url) {
        trackEvent('Carrier Signup without USDOT');
        window.location.href = `${url}&next=/tms/loads?utm_source=carrier_signup`;
      }
      setVerifying(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogBody>
        <USDOTWarningIcon />
        <Wrapper>
          <TextHeader center={true}>Continue Without USDOT?</TextHeader>
          <WarningAlert>
            <Text>
              <b>Note:</b> Some shippers require a valid USDOT to send offers.
              Without a USDOT, you may have limited access to loads on Super
              Loadboard and adding it later can take 2–3 business days for
              verification.
            </Text>
          </WarningAlert>

          <ListWrapper>
            <Text bold={true}>What can you do instead?</Text>
            <List>
              <li>
                Find your USDOT number on the{' '}
                <a
                  href="https://safer.fmcsa.dot.gov/CompanySnapshot.aspx"
                  target="_blank"
                >
                  SAFER website
                </a>
              </li>
              <li>
                <a
                  href="https://portal.fmcsa.dot.gov/UrsRegistrationWizard/"
                  target="_blank"
                >
                  Apply for a USDOT number
                </a>
              </li>
            </List>
          </ListWrapper>
        </Wrapper>

        <CheckboxGroup>
          <Text bold={true}>
            Confirm you meet the following criteria to proceed without a USDOT:
          </Text>
          {usdotReasons.map((reason) => (
            <Field name={reason.name}>
              {({ input }) => (
                <Checkbox
                  {...input}
                  label={
                    <Label>
                      {reason.label}{' '}
                      <Link href={reason.link} target="_blank">
                        {reason.linkText}
                      </Link>
                    </Label>
                  }
                />
              )}
            </Field>
          ))}
        </CheckboxGroup>
        <DialogFooter>
          <Button
            disabled={verifying || !isNotDisabled}
            onClick={handleContinueWithoutUSDOT}
          >
            Continue Without USDOT
          </Button>
          <Button disabled={verifying} variant="contained" onClick={onClose}>
            Go Back to Enter USDOT
          </Button>
        </DialogFooter>
      </DialogBody>
    </Dialog>
  );
}
