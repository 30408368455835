import { ThemeProvider } from '@superdispatch/ui';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Toaster } from 'react-hot-toast';
import { QueryClient, QueryClientProvider } from 'react-query';
import { RouterProvider } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';
import { createGlobalStyle } from 'styled-components';
import reportWebVitals from './reportWebVitals';
import { router } from './routes';
import AlertIcon from './shared/assets/ic-alert-icon.svg?react';
import { initAnalytics } from './shared/helpers/AnalyticsHelpers';
import { bootstrap } from './shared/utils/bootstrap';
import { ErrorBoundary } from './shared/utils/ErrorBoundary';

bootstrap();

const GlobalStyles = createGlobalStyle`
  * {
    font-family: Helvetica, sans-serif;
    font-size:16px;
  }

  html, body, #root{
    color:#192334;
    padding:0;
    margin:0;
    background-color:white;
    overflow-x:hidden;
    position:relative;
    @media(max-width:768px){
      height:unset;
    }
  }

  input{
    font-size: 16px !important;
  }

  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
  }

  input[type=number] {
    -moz-appearance: textfield;
  }
`;

const queryClient = new QueryClient();

export function App() {
  const fadeIn = useSpring({ from: { opacity: 0 }, to: { opacity: 1 } });

  useEffect(() => {
    initAnalytics();
  }, []);

  return (
    <ErrorBoundary>
      <ThemeProvider mode="light">
        <QueryClientProvider client={queryClient}>
          <GlobalStyles />
          <Toaster
            toastOptions={{
              className: '',
              duration: 4000,
              position: 'bottom-center',
              style: {
                padding: '16px',
              },
              iconTheme: {
                primary: 'white',
                secondary: '#192334',
              },
              success: {
                style: {
                  background: '#192334',
                  color: 'white',
                  borderRadius: '4px',
                  boxShadow: '0px 12px 20px 0px rgba(25, 35, 52, 0.22)',
                },
              },
              error: {
                style: {
                  background: '#FFEDEB',
                  color: '#C31909',
                  borderRadius: '4px',
                  boxShadow: '0px 12px 20px 0px rgba(25, 35, 52, 0.22)',
                },
                icon: <AlertIcon />,
              },
            }}
          />
          <animated.div style={fadeIn}>
            <RouterProvider router={router} />
          </animated.div>
        </QueryClientProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
