import { ReactNode } from 'react';
import { Field, FieldMetaState } from 'react-final-form';
import { TextField } from 'shared/ui/TextField';
import styled from 'styled-components';

const StyledSelectField = styled(TextField)`
  .MuiInputBase-root {
    height: 56px;
    background-color: white;
  }
  .MuiSelect-root {
    font-size: 16px;
  }
`;

const formatError = (meta: FieldMetaState<unknown>) => {
  if (meta.error && meta.touched) {
    return <span>{meta.error}</span>;
  }
  if (meta.submitError && !meta.touched) {
    return <span>{meta.submitError}</span>;
  }
};

interface Props {
  name: string;
  validate?: (value?: string) => string | undefined;
  children: ReactNode;
  placeholder?: string;
  label?: ReactNode;
}

export function SelectField({ children, placeholder, label, ...props }: Props) {
  return (
    <Field {...props}>
      {({ input: { value, onChange }, meta }) => (
        <StyledSelectField
          select={true}
          value={value}
          label={label}
          error={!!formatError(meta)}
          helperText={formatError(meta)}
          onChange={(event) => {
            onChange(event.target.value);
          }}
          SelectProps={{
            displayEmpty: true,
            renderValue: (selected) => {
              if (!selected) {
                return placeholder;
              }

              return selected as string;
            },
          }}
        >
          {children}
        </StyledSelectField>
      )}
    </Field>
  );
}
