import { SignupCarrier } from 'carrier/SignupCarrier';
import { SignupCarrierLegacy } from 'carrier/SignupCarrierLegacy';
import { createBrowserRouter, Navigate, useParams } from 'react-router-dom';
import { SelectProduct } from 'SelectProduct';
import { useCarrierFeatureToggles } from 'shared/useCarrierFeatureToggles';
import { SignupShipperPage } from 'shipper/SignupShipperPage';

const CarrierRoute = () => {
  const params = useParams();
  const { features, isLoading } = useCarrierFeatureToggles();

  const routeValues = features?.email_verification
    ? ['create-account', 'contact-info', 'additional-info', 'verify-usdot']
    : ['create-account', 'additional-info', 'verify-usdot'];

  if (!routeValues.includes(params.step || '')) {
    return <Navigate to="/carrier/create-account" />;
  }

  if (isLoading) {
    return null;
  }

  if (features?.email_verification) {
    return <SignupCarrier routeValues={routeValues} />;
  }

  return <SignupCarrierLegacy routeValues={routeValues} />;
};

export const router = createBrowserRouter([
  {
    index: true,
    element: <SelectProduct />,
  },
  {
    path: '/shipper',
    element: <SignupShipperPage />,
  },
  {
    path: '/carrier/:step',
    children: [
      {
        index: true,
        element: <CarrierRoute />,
      },
      {
        path: '*',
        element: <Navigate to="/carrier/create-account" />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/" />,
  },
]);
