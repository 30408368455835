import {
  FormControl,
  StandardTextFieldProps,
  TextField as MuiTextField,
} from '@material-ui/core';
import { useUID } from '@superdispatch/ui';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { HelpTooltip } from './HelpTooltip';

const StyledTextField = styled(MuiTextField)`
  input {
    height: 44px;
    background-color: white;
  }
`;

const StyledTextFieldLabel = styled.label`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  display: flex;
  gap: 4px;
  align-items: center;
`;

interface Props extends StandardTextFieldProps {
  tooltipContent?: ReactNode;
}

export function TextField({ label, tooltipContent, ...props }: Props) {
  const uid = useUID();
  return (
    <FormControl>
      <StyledTextFieldLabel htmlFor={uid}>
        {label}

        {tooltipContent && <HelpTooltip content={tooltipContent} />}
      </StyledTextFieldLabel>
      <StyledTextField id={uid} {...props} />
    </FormControl>
  );
}
