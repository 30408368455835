import { useState } from 'react';
import styled from 'styled-components';

interface CarrierLogoProps {
  img: string;
  name: string;
}

const Logo = styled.img`
  width: 56px;
  height: 56px;
  border-radius: 50%;
  object-fit: cover;
`;

const LogoBox = styled.div`
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: #e1e5ea;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #5b6371;
  font-size: 21px;
`;

const getCarrierNameUppercase = (name: string) => {
  return name
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase())
    .join('')
    .slice(0, 2);
};

export const CarrierLogo = ({ img, name }: CarrierLogoProps) => {
  const [error, setError] = useState(false);

  if (error || !img) {
    return <LogoBox>{!!name && getCarrierNameUppercase(name)}</LogoBox>;
  }
  return (
    <Logo
      src={img}
      alt={name}
      onError={() => {
        setError(true);
      }}
    />
  );
};
