import { MenuItem } from '@material-ui/core';
import * as React from 'react';
import { Field, FieldMetaState } from 'react-final-form';
import { SelectField } from 'shared/form/SelectField';
import styled from 'styled-components';
import { SignupCarrierType } from '../../data/DTOs';
import ArrowForwardIcon from '../../shared/assets/arrow-forward.svg?react';
import WhiteTickIcon from '../../shared/assets/white-tick-icon.svg?react';
import { CaptchaWidget } from '../../shared/helpers/CaptchaWidget';
import { isSelected, required } from '../../shared/helpers/validationHelpers';
import { Button } from '../../shared/ui/Button';
import { FormGroup } from '../../shared/ui/FormGroup';
import { RadioGroupField } from '../../shared/ui/RadioGroupField';
import { TextField } from '../../shared/ui/TextField';
import { useCarrierFeatureToggles } from '../../shared/useCarrierFeatureToggles';
import { sendToWebhook } from '../../shared/webhooks/webhook';
const Wrapper = styled.div`
  margin-top: 20px;
`;
const StyledArrowForwardIcon = styled(ArrowForwardIcon)`
  margin-left: 10px;
`;

const StyledWhiteTickIcon = styled(WhiteTickIcon)`
  margin-left: 10px;
`;

const RoleWrapper = styled(FormGroup)`
  flex-direction: column;
`;

const StyledSelectField = styled(TextField)`
  .MuiInputBase-root {
    height: 56px;
    background-color: white;
  }
  .MuiSelect-root {
    font-size: 16px;
  }
`;

const referralOptions = [
  'Shipper Request',
  'Broker Request',
  'Google Search',
  'Email',
  'Social Media',
  'Events / Vendor Booth / Conference',
  'Other',
];
const roles = [
  'Dispatcher',
  'Fleet Manager',
  'Owner',
  'Billing',
  'Driver',
  'Other',
];
const driversCountOptions = [
  { label: '1', value: 1 },
  { label: '2-5', value: 2 },
  { label: '6-10', value: 6 },
  { label: '10+', value: 10 },
];
const perBrokerRequest = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

const validations = {
  role: isSelected(roles, 'Please choose one of the options'),
  number_of_trucks: isSelected(
    driversCountOptions.map((c) => c.value),
    'Please choose one of the options',
  ),
  comment: isSelected(referralOptions, 'Please choose one of the options'),
  referralSource: required('Please enter a source'),
  roleOther: required('Please enter your role'),
};

const errors = (meta: FieldMetaState<unknown>) => {
  if (meta.error && meta.touched) {
    return <span>{meta.error}</span>;
  }
  if (meta.submitError && !meta.touched) {
    return <span>{meta.submitError}</span>;
  }
};

const ConditionalField = ({ when, is, children }: any) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
);

interface AdditionalInfoProps {
  submitting?: boolean;
  onCaptchaTokenChange: (token: string | null) => void;
  wizardData: SignupCarrierType & { carrierData?: any; carrierStatus: boolean };
}

export function AdditionalInfo(props: AdditionalInfoProps) {
  const { features } = useCarrierFeatureToggles();

  React.useEffect(() => {
    document.title = 'Carrier Sign Up | Additional Information';
  }, []);

  React.useEffect(() => {
    if (import.meta.env.VITE_TARGET === 'production') {
      void sendToWebhook({
        first_name: props.wizardData.first_name,
        last_name: props.wizardData.last_name,
        email: props.wizardData.email,
        phone_number: props.wizardData.phoneNumber,
        email_subscription: props.wizardData.subscriptions,
        sms_subscription: props.wizardData.subscriptions,
        source: 'CTMS_Website',
      });
    }
  }, [props.wizardData]);

  return (
    <Wrapper>
      <RoleWrapper>
        <Field name="role" validate={(v) => validations.role(v)}>
          {({ input, meta }) => (
            <RadioGroupField
              column={true}
              checked={roles.findIndex((r) => r === input.value)}
              values={roles.map((role, index) => ({
                label: role,
                value: index,
              }))}
              name="role"
              label="Select your role"
              errorMessage={errors(meta)}
              onClick={(value: any) => {
                input.onChange(roles[value]);
              }}
            />
          )}
        </Field>
        <ConditionalField when="role" is="Other">
          <Field name="role_other" validate={validations.roleOther}>
            {({ input, meta }) => (
              <TextField
                {...input}
                placeholder="Enter your role"
                error={!!errors(meta)}
                helperText={errors(meta)}
                inputProps={{
                  maxLength: 255,
                }}
              />
            )}
          </Field>
        </ConditionalField>
      </RoleWrapper>
      <FormGroup>
        <Field name="number_of_trucks" validate={validations.number_of_trucks}>
          {({ input, meta }) => (
            <RadioGroupField
              columnOnMobile={true}
              checked={driversCountOptions.findIndex(
                (r) => r.value === input.value,
              )}
              values={driversCountOptions}
              name="number_of_trucks"
              label="How many drivers does your company manage?"
              errorMessage={errors(meta)}
              onClick={(value: any) => {
                input.onChange(value);
              }}
            />
          )}
        </Field>
      </FormGroup>

      <FormGroup>
        <SelectField
          name="comment"
          placeholder="Select a source..."
          label="How did you hear about us?"
          validate={(v) => validations.comment(v)}
        >
          {referralOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </SelectField>
      </FormGroup>
      <ConditionalField when="comment" is="Other">
        <FormGroup>
          <Field name="referralSource" validate={validations.referralSource}>
            {({ input, meta }) => (
              <TextField
                {...input}
                error={!!errors(meta)}
                helperText={errors(meta)}
                placeholder="Please, specify the source..."
                inputProps={{
                  maxLength: 255,
                }}
              />
            )}
          </Field>
        </FormGroup>
      </ConditionalField>
      <Button
        type="submit"
        size="large"
        fullWidth={true}
        variant="contained"
        isLoading={props.submitting}
      >
        {props.wizardData.country !== 'US' ? (
          <>
            Complete Sign Up
            <StyledWhiteTickIcon />
          </>
        ) : (
          <>
            Continue
            <StyledArrowForwardIcon />
          </>
        )}
      </Button>

      {features?.no_usdot_signup && props.wizardData.country !== 'US' && (
        <Wrapper>
          <CaptchaWidget onTokenChange={props.onCaptchaTokenChange} />
        </Wrapper>
      )}
    </Wrapper>
  );
}
