import {
  FormControl,
  IconButton,
  TextField as MuiTextField,
  TextFieldProps,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useUID } from '@superdispatch/ui';
import * as React from 'react';
import styled from 'styled-components';

const StyledTextField = styled(MuiTextField)`
  input {
    height: 44px;
  }
  background-color: white;
`;

const StyledTextFieldLabel = styled.label`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
`;

export function PasswordField({ label, ...props }: TextFieldProps) {
  const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);
  const uid = useUID();
  return (
    <FormControl>
      <StyledTextFieldLabel htmlFor={uid}>{label}</StyledTextFieldLabel>
      <StyledTextField
        {...props}
        fullWidth={true}
        id={uid}
        type={isPasswordVisible ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <IconButton
              edge="end"
              onClick={() => {
                setIsPasswordVisible(!isPasswordVisible);
              }}
            >
              {isPasswordVisible ? (
                <Visibility color="action" />
              ) : (
                <VisibilityOff color="action" />
              )}
            </IconButton>
          ),
        }}
      />
    </FormControl>
  );
}
