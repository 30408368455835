import emailSpellChecker from '@zootools/email-spell-checker';
import * as React from 'react';
import { Field, useField, useFormState } from 'react-final-form';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import { TermsOfService } from '../../core/TermsOfService';
import {
  APIResponse,
  isAPIErrorResponse,
  verifyEmail,
} from '../../data/CarrierAPI';
import ArrowForwardIcon from '../../shared/assets/arrow-forward.svg?react';
import {
  isValidEmail,
  required,
  validatePassword,
} from '../../shared/helpers/validationHelpers';
import { Button } from '../../shared/ui/Button';
import { errors, FinalFormTextField } from '../../shared/ui/FinalFormTextField';
import { FormGroup } from '../../shared/ui/FormGroup';
import { PasswordField } from '../../shared/ui/PasswordField';
import { PasswordStrength } from '../../shared/ui/PasswordStrength';
import { useCarrierFeatureToggles } from '../../shared/useCarrierFeatureToggles';
import { VerifyEmail } from './VerifyEmail';

const AlertText = styled.p<{ primary?: boolean; bold?: boolean }>`
  margin: 0;
  font-size: 16px;
  font-weight: ${({ bold }) => (bold ? '500' : '400')};
  line-height: 24px;
  letter-spacing: 0.35px;
  color: ${({ primary }) => (primary ? '#192334' : '#6a707c')};
`;

const Wrapper = styled.div`
  margin-top: 20px;
`;
const StyledArrowForwardIcon = styled(ArrowForwardIcon)`
  margin-left: 10px;
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const HelperText = styled.span`
  font-size: 16px;
  margin: 5px 0;
  line-height: 20px;
  color: #8f949e;
  white-space: nowrap;
`;

const Link = styled.a`
  color: #0063db;
  cursor: pointer;
  text-decoration: none;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const FormBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const Alert = styled.div`
  background-color: #f3f5f8;
  padding: 16px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const validations = {
  password: required('Please enter a password'),
  email: isValidEmail('Please enter a valid email'),
};

interface CreateAccountProps {
  onUpdateTitle: (title: string) => void;
}

export function CreateAccount({ onUpdateTitle }: CreateAccountProps) {
  const {
    submitErrors,
    values,
    submitFailed: isSubmitFailed,
    valid,
  } = useFormState();
  const { features } = useCarrierFeatureToggles();
  const [showVerifyEmail, setShowVerifyEmail] = React.useState(false);
  const [sendVerificationCodeError, setSendVerificationCodeError] =
    React.useState<string>();
  const [previousEmail, setPreviousEmail] = React.useState<string>();
  const [sniperLink, setSniperLink] = React.useState<string>(); // Sniper link is used to open the email inbox in a new tab with the verification code filtered
  const { input, meta } = useField('email', { validate: validations.email });

  const sendVerificationCodeMutation = useMutation({
    mutationFn: verifyEmail,
    onMutate: () => {
      setSendVerificationCodeError(undefined);
    },
    onSuccess: (response: APIResponse<{ sniper_link: string }>) => {
      if (isAPIErrorResponse(response)) {
        response.error.type === 'TooManyRequests'
          ? toast.error('Too many requests, please try again later')
          : setSendVerificationCodeError(response.error.user_message);
      } else {
        handleShowVerifyEmail();
        setSniperLink(response.data?.sniper_link);
      }
    },
    onError: (error: Error) => {
      toast.error(error.message);
    },
  });

  const suggestion = emailSpellChecker.run({
    email: values.email || '',
  });

  React.useEffect(() => {
    document.title = 'Carrier Sign Up | Create Account';
  }, []);

  const handleShowVerifyEmail = () => {
    setShowVerifyEmail(true);
    onUpdateTitle('Confirm Email');
  };

  return (
    <Wrapper>
      {showVerifyEmail ? (
        <VerifyEmail
          sniperLink={sniperLink}
          onBack={() => {
            setShowVerifyEmail(false);
            onUpdateTitle('Create Account');
            setPreviousEmail(values.email);
          }}
        />
      ) : (
        <FormWrapper>
          {features?.no_usdot_signup && (
            <Alert>
              <AlertText bold={true}>Note:</AlertText>
              <AlertText primary={true}>
                A USDOT number may be required to sign up. If you're joining an
                existing Super Dispatch carrier, ask your account admin to
                invite you.
              </AlertText>
            </Alert>
          )}
          <FormBox>
            <FormGroup>
              <FinalFormTextField
                name="email"
                validate={validations.email}
                label="Email"
                maxLength={255}
                onChange={() => {
                  setSendVerificationCodeError(undefined);
                }}
                errorMessage={submitErrors?.email || sendVerificationCodeError}
                helperText={
                  <>
                    {sendVerificationCodeError && (
                      <HelperText>
                        If you have an account,{' '}
                        <Link
                          href={
                            import.meta.env.VITE_TARGET === 'production'
                              ? 'https://carrier.superdispatch.com/tms/login/'
                              : 'https://staging.carrier.superdispatch.org/tms/login'
                          }
                        >
                          log in instead
                        </Link>
                      </HelperText>
                    )}
                    {suggestion && !(errors(meta) || submitErrors?.email) && (
                      <HelperText>
                        Do you mean{' '}
                        <Link
                          onClick={(e) => {
                            e.preventDefault();
                            input.onChange(suggestion.full);
                          }}
                        >
                          {' '}
                          {suggestion.domain}
                        </Link>
                        ?
                      </HelperText>
                    )}
                  </>
                }
              />
            </FormGroup>

            <FormGroup>
              <Field
                name="password"
                maxLength={255}
                validate={validatePassword}
              >
                {({ input, meta }) => (
                  <PasswordField
                    {...input}
                    label="Password"
                    placeholder="Enter 8 characters or more"
                    error={!!meta.error && isSubmitFailed}
                    helperText={!!meta.error && isSubmitFailed && ' '}
                  />
                )}
              </Field>
            </FormGroup>

            <PasswordStrength value={values.password} />

            <Wrapper>
              <Button
                size="large"
                color="primary"
                variant="contained"
                onClick={(e) => {
                  if (valid) {
                    e.preventDefault();
                    if (values.email !== previousEmail) {
                      sendVerificationCodeMutation.mutate(values.email);
                    } else {
                      handleShowVerifyEmail();
                    }
                  }
                }}
                isLoading={sendVerificationCodeMutation.isLoading}
                disabled={!valid}
                fullWidth={true}
              >
                Continue <StyledArrowForwardIcon />
              </Button>
            </Wrapper>
            <TermsOfService />
          </FormBox>
        </FormWrapper>
      )}
    </Wrapper>
  );
}
