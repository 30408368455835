import { SuspendedPhoneField, usePhoneService } from '@superdispatch/phones';
import { Suspense } from 'react';
import { Field, FieldMetaState } from 'react-final-form';
import styled from 'styled-components';

const StyledPhoneField = styled(SuspendedPhoneField)`
  .MuiFormLabel-root {
    font-weight: 600;
    font-size: 16px;
  }
  .MuiInputBase-root {
    background: white;
    height: 56px;
  }
  .MuiInputAdornment-root {
    .MuiTypography-root {
      font-size: 16px;
    }
  }
`;

const getError = (meta: FieldMetaState<unknown>) => {
  if (meta.error && meta.touched) {
    return <span>{meta.error}</span>;
  }
  if (meta.submitError && !meta.touched) {
    return <span>{meta.submitError}</span>;
  }
};

interface Props {
  name: string;
}

export function PhoneField({ name }: Props) {
  return (
    <Suspense fallback={'Loading phone field'}>
      <PhoneFieldContent name={name} />
    </Suspense>
  );
}

function PhoneFieldContent({ name }: Props) {
  const phoneService = usePhoneService();
  return (
    <Field
      name={name}
      validate={(value) =>
        !value ? 'Please enter a phone number' : phoneService.validate(value)
      }
    >
      {({ input, meta }) => (
        <StyledPhoneField
          value={input.value}
          onChange={input.onChange}
          onBlur={() => {
            input.onBlur();
          }}
          placeholder="Your phone number"
          label="Phone Number"
          error={!!getError(meta)}
          helperText={getError(meta)}
        />
      )}
    </Field>
  );
}
